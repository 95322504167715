
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAssessmentHistory, getAdminReports, getCompletedAssessment } from 'src/redux/thunks'
import { updateReportContent } from 'src/redux/actions'
import { Button, Box, Container, Typography } from '@material-ui/core';
import HeaderButtons from 'src/components/shared/HeaderButtons';
import Footer from 'src/components/shared/Footer';
import Individual from './ViewReports/Individual';
import Grouping from './ViewReports/Grouping';


const ViewAssessment = (props) => {

    let location = useLocation();

    const [showGrouping, setShowGrouping] = useState(false)
    const [showIndividual, setShowIndividual] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);

    //redux variables
    const {
        userInfo,
        history,
        isLoading,
        getList,
        getAdminReports,
        getCompletedAssessment,
        isCreateEvalLoading,
        adminReports,
        updateReportContent
    } = props;

    useEffect(() => {
        setShowGrouping(false)
        setShowIndividual(false)
    }, [location])

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    //dispatch assessment history
    const getHistory = () => {
        getList(userInfo.email);
    }

    useEffect(() => {
        updateReportContent(adminReports)
    }, [adminReports])

    useEffect(() => {
        //update assessment history: on login, on refresh, on nav click
        getHistory()
        getAdminReports()

    }, [userInfo]);

    const calculateMargin = () => {

        if (width < 768) {
            return 10; // Minimum margin for small screens
        } else if (width > 1500) {
            return 40; // Maximum margin for large screens
        } else if (width === 1366 || width === 1440) {
            // Specific adjustments for common screen sizes
            return calculateAdjustedMargin(width);
        } else {
            // Linear interpolation between 768 (10 margin) and 1500 (40 margin)
            return 10 + ((width - 768) * (40 - 10) / (1500 - 768));
        }
    }

    // Adjust margins specifically for problematic widths
    function calculateAdjustedMargin(width) {
        if (width === 1366) {
            return 30; // Slightly less than the interpolated value might normally be
        } else if (width === 1440) {
            return 35; // Adjusted to prevent being too far to the right
        }
        return calculateMargin(width); // Fallback to the regular calculation
    }

     //#226825 new green color
    return (
        <>
            <Helmet>
                <title>Vector Reports | View</title>
            </Helmet>
            <Box my={8} mx={4} sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth={"lg"}>
                    <Typography color="textPrimary" variant="h2" style={{ fontWeight: 600 }}>
                        View Prior Assessments
                    </Typography>
                    <HeaderButtons />
                </Container>
                <Box mt={0}>
                    <Container maxWidth={"lg"}>
                        {!showGrouping && !showIndividual &&
                            <Box sx={{ width: 310, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: calculateMargin() }}>
                                <Button onClick={() => setShowGrouping(!showGrouping)} sx={{ width: 140, marginRight: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }} size="large" variant="contained">
                                    Grouping <br /> Reports
                                </Button>
                                <Button onClick={() => setShowIndividual(!showIndividual)} sx={{ width: 140, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }} size="large" variant="contained">
                                    Individual <br /> Reports
                                </Button>
                               
                            </Box>
                        }

                        {showGrouping && <Grouping />}
                        {showIndividual && <Individual />}
                    </Container>
                </Box>
            </Box>
            <Footer />
        </>
    );
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
    history: state.assessment.assessmentHistory,
    isLoading: state.assessment.isAssessmentLoading,
    isCreateEvalLoading: state.takingAssessment.isCreateEvalLoading,
    reportContent: state.takingAssessment.reports, //fetch report content on open  
    adminReports: state.adminReports.reports,
});

const mapDispatchToProps = dispatch => ({
    getList: email => dispatch(getAssessmentHistory(email)),
    getAdminReports: () => dispatch(getAdminReports()),
    getCompletedAssessment: code => dispatch(getCompletedAssessment(code)),
    updateReportContent: content => dispatch(updateReportContent(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAssessment);