import { db, timestamp } from "../../services/firebase";

import {
    sendEmailRequest,
    getTemplateRequest,
    getTemplatesFailure,
    getTemplatesSuccess,
    updateTemplateFailure,
    updateTemplateRequest,
    updateTemplateSuccess,
    sendEmailFailure,
    sendEmailSuccess
} from "../actions/emails"

//get templates
export const getEmailTemplates = () => async dispatch => {
    dispatch(getTemplateRequest())

    try {
        //load all reports
        let query = db.collection("email-templates")
        query.onSnapshot(querySnapshot => {
            let templatesList = [];

            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    const data = doc.data()
                    templatesList.push(data)
                } else {
                    dispatch(getTemplatesFailure('Report Type not found'))
                }
            });
            //dispatch reports 
            dispatch(getTemplatesSuccess(templatesList))
        })

    } catch (error) {
        console.log("Error: ", error.message)
        dispatch(getTemplatesFailure(error.message))
    }
}

// update
export const updateEmailTemplate = (template, dataVars) => async dispatch => {

    dispatch(updateTemplateRequest())

    try {

        const templates = db.collection('email-templates')
        const query = templates.where('name', '==', template.name).limit(1)

        query.get().then(snapshot => {
            //get assessment
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //update assessment
                    await doc.ref.update({
                        subject: template.subject,
                        html: template.html,
                        data: dataVars
                    }).then((data) => {
                        dispatch(updateTemplateSuccess(template))
                    }).catch(error => {
                        // The document probably doesn't exist.
                        dispatch(updateTemplateFailure(error.message))
                    });
                } else {
                    dispatch(updateTemplateFailure("Template not found"))
                }
            });
        }).catch(error => {
            dispatch(updateTemplateFailure(error.message))
            console.log(error.message)
        })

    } catch (error) {
        console.log("Error: ", error.message)
        dispatch(updateTemplateFailure(error.message))
    }
}

export const createEmail = (templateName, to, input) => async dispatch => {

    dispatch(sendEmailRequest())
    console.log("input: ", input)
    try {
        const templateRef = db.collection('email-templates')

        let template
        let templateQueryRes = await templateRef.where('name', '==', templateName).limit(1).get()
        templateQueryRes.forEach(doc => {
            if (doc.exists) {
                template = doc.data()
            }
        })

        if (template) {
            let _data = {
                to: [to],
                template: {
                    name: templateName,
                    data: buildEmailDataObject(template?.data)
                }
            }

            console.log("_data: ", _data)

            //     let response = await db.collection('mail').add(_data)

            //     console.log("res: ", response)

            sendEmailSuccess()
        }
    } catch (error) {
        console.log("error: ", error)
        dispatch(sendEmailFailure(error.message))
    }
}


const buildEmailDataObject = (data) => {

    let obj


    return obj
}