import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, FormHelperText, Link, TextField, Typography, Card, CardContent, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import * as Yup from 'yup';
import { Formik } from 'formik';

import { updateAssessor, updateTakingAssessment } from 'src/redux/actions';
import Footer from 'src/components/shared/Footer';

const InformationForm = (props) => {

    const {
        updateAssessor,
        updateTakingAssessment,
        userInfo,
        assessments,
        accessCode,
    } = props

    const [name, setName] = useState('')
    const [isAgency, setIsAgency] = useState(false)

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/app/assessment-details', { replace: true })
    }

    useEffect(() => {
        document.getElementById('information-top').scrollTo({
            top: 0,
            behavior: "smooth",
        });
        //add assessor name so that we can show or hide input field
        assessments.forEach(element => {
            if (element.accessCode == accessCode && element.storeId === 'via-agency') {
                setIsAgency(true)
            }
        })
    }, [])

    return (
        <>
            <Helmet>
                <title>Vector Reports | Information</title>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 8 }} id='information-top' >
                {/* Header */}
                <Container maxWidth='md' >
                    <Box mb={4}>
                        <Typography color="textPrimary" variant="h2" >
                            Identify Organization to be Assessed
                        </Typography>
                        <Typography color="textSecondary" gutterBottom variant="body2" >
                            Enter Company or Organization
                            information below. This will be printed
                            on the Report upon completion.
                        </Typography>
                    </Box>
                </Container>
                <Container maxWidth="md" mt={2}>
                    <Formik
                        initialValues={{
                            //assessor
                            assessorName: '',
                            assessorTitle: '',
                            assessorRole: '',
                            assessorPhone: '',
                            internalExternal: '',
                            //organization
                            companyName: '',
                            projectName: '',
                            projectDescription: '',
                            companyDivision: '',
                            companyAddress: '',
                            companyCity: '',
                            companyState: '',
                            companyZip: '',
                            pointOfContact: '',
                            pointOfContactEmail: '',
                            yearFormed: '',
                            nrOfEmployees: '',
                            improvementsUsed: '',
                            otherImprovements: ''
                            // typeOfBusiness: ''
                        }}
                        validationSchema={
                            Yup.object().shape({

                                //organization
                                companyName: Yup.string().max(255).required('Company Name is required'),
                                pointOfContact: Yup.string().max(60).required('POC is required'),
                                yearFormed: Yup.number().max(2025),
                                nrOfEmployees: Yup.number().max(2025),
                                improvementsUsed: Yup.string().max(60),
                                otherImprovements: Yup.string().max(255)

                            })
                        }
                        onSubmit={data => {
                            //save data, get and open evaluation page
                            const info = {
                                assessor: {
                                    email: userInfo.email,
                                    name: `${userInfo.name.first} ${userInfo.name.last} `,
                                    // title: data.assessorTitle,
                                    // role: data.assessorRole,
                                    phone: userInfo.phone,
                                    isInternal: data.internalExternal == 'Internal' ? true : false
                                },
                                company: {
                                    name: data.companyName,
                                    projectName: data.projectName,
                                    projectDescription: data.projectDescription,
                                    division: data.companyDivision,
                                    address: data.companyAddress,
                                    city: data.companyCity,
                                    state: data.companyState,
                                    zip: data.companyZip,
                                    pointOfContact: data.pointOfContact,
                                    nrOfEmployees: parseInt(data.nrOfEmployees),
                                    yearFormed: parseInt(data.yearFormed),
                                    improvementsUsed: data.improvementsUsed,
                                    otherImprovements: data.otherImprovements

                                }
                            }

                            updateAssessor(info)
                            updateTakingAssessment(true)

                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form onSubmit={handleSubmit}>

                                <Card>
                                    <CardContent>
                                        <Box px={8}>
                                            {/* Company Info */}
                                            <Box sx={{ my: 3 }}>
                                                <Typography color="textPrimary" variant="h2" >
                                                    Enter information below:
                                                </Typography>
                                            </Box>
                                            <TextField
                                                error={Boolean(touched.companyName && errors.companyName)}
                                                fullWidth
                                                helperText={touched.companyName && errors.companyName}
                                                label="Company Name"
                                                margin="normal"
                                                name="companyName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.companyName}
                                                variant="outlined"
                                                type="text"
                                            />
                                            <TextField
                                                error={Boolean(touched.projectName && errors.projectName)}
                                                fullWidth
                                                helperText={touched.projectName && errors.projectName}
                                                label="Project Assessed (if not entire business) or Team Name"
                                                margin="normal"
                                                name="projectName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.projectName}
                                                variant="outlined"
                                                type="text"
                                            />
                                            <TextField
                                                error={Boolean(touched.pointOfContact && errors.pointOfContact)}
                                                fullWidth
                                                helperText={touched.pointOfContact && errors.pointOfContact}
                                                label="Name of knowledgeable company representative"
                                                margin="normal"
                                                name="pointOfContact"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.pointOfContact}
                                                variant="outlined"
                                                type="text"
                                            />

                                            {isAgency && (
                                                <>
                                                    <TextField
                                                        error={Boolean(touched.nrOfEmployees && errors.nrOfEmployees)}
                                                        fullWidth
                                                        helperText={touched.nrOfEmployees && errors.nrOfEmployees}
                                                        label="Number of Employees"
                                                        margin="normal"
                                                        name="nrOfEmployees"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.nrOfEmployees}
                                                        variant="outlined"
                                                        type="text"
                                                    />
                                                    <TextField
                                                        error={Boolean(touched.yearFormed && errors.yearFormed)}
                                                        fullWidth
                                                        helperText={touched.yearFormed && errors.yearFormed}
                                                        label="Year company formed"
                                                        margin="normal"
                                                        name="yearFormed"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.yearFormed}
                                                        variant="outlined"
                                                        type="text"
                                                    />

                                                    <FormControl fullWidth sx={{mt: 2}}>
                                                        <InputLabel id="select-label">Recent major improvement efforts used?</InputLabel>
                                                        <Select
                                                            labelId="select-label"
                                                            label="Recent major improvement efforts used?"
                                                            error={Boolean(touched.improvementsUsed && errors.improvementsUsed)}
                                                            helperText={touched.improvementsUsed && errors.improvementsUsed}
                                                            fullWidth
                                                            margin="normal"
                                                            name="improvementsUsed"
                                                            id="improvementsUsed"
                                                            value={values.improvementsUsed}
                                                            onChange={handleChange}
                                                           
                                                        >
                                                            <MenuItem value={'No actions taken recently,'}>No actions taken recently</MenuItem>
                                                            <MenuItem value={'Improved on our own internally (DIY),'}>Improved on our own internally (DIY)</MenuItem>
                                                            <MenuItem value={'Educational support (classes, reading, workshops)'}>Educational support (classes, reading, workshops)</MenuItem>
                                                            <MenuItem value={'Consultant or Mentor'}>Consultant or Mentor</MenuItem>
                                                            <MenuItem value={'Local SBDC or APEX accelerator'}>Local SBDC or APEX accelerator</MenuItem>
                                                            <MenuItem value={'Local Start-Up incubator'}>Local Start-Up incubator</MenuItem>
                                                            <MenuItem value={'Local business accelerator'}>Local business accelerator</MenuItem>
                                                            <MenuItem value={'local SBIR/STTR expert'}>local SBIR/STTR expert</MenuItem>
                                                            <MenuItem value={'Local AI consultant'}>Local AI consultant</MenuItem>
                                                            <MenuItem value={'Other'}>Other</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    
                                                    {values.improvementsUsed === 'Other' && <TextField
                                                        error={Boolean(touched.otherImprovements && errors.otherImprovements)}
                                                        fullWidth
                                                        helperText={touched.otherImprovements && errors.otherImprovements}
                                                        label="Other"
                                                        margin="normal"
                                                        name="otherImprovements"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.otherImprovements}
                                                        variant="outlined"
                                                        type="text"
                                                    />}

                                                </>
                                            )}


                                            {Boolean(touched.policy && errors.policy) && (
                                                <FormHelperText error>
                                                    {errors.policy}
                                                </FormHelperText>
                                            )}
                                            <Box mt={8} className={classes.buttonContainer}>
                                                <Button variant="contained" color="error" sx={{ width: 100 }}
                                                    onClick={() => handleBack()}>
                                                    Back
                                                </Button>
                                                <Box className={classes.buttonsRight} >
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        sx={{ width: 100, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}
                                                    >
                                                        Continue
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </Container>
                <Footer />
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '77%',
        height: '100%',
        paddingBottom: 50,
        width: '100%'
    },
    buttonsLeft: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonsRight: {
        display: 'flex',
        flexDirection: 'row',

    }
});
const states = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]

const industries = [
    "Accounting",
    "Airlines/Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts & Crafts",
    "Automotive",
    "Aviation & Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies & Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Dairy",
    "Defense & Space",
    "Design",
    "Education Management",
    "E-learning",
    "Electrical & Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food & Beverages",
    "Food Production",
    "Fundraising",
    "Furniture",
    "Gambling & Casinos",
    "Glass, Ceramics & Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness & Fitness",
    "Higher Education",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import & Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology & Services",
    "Insurance",
    "International Affairs",
    "International Trade & Development",
    "Internet",
    "Investment Banking/Venture",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure & Travel",
    "Libraries",
    "Logistics & Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Marketing & Advertising",
    "Market Research",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Device",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Mining & Metals",
    "Motion Pictures & Film",
    "Museums & Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Nonprofit Organization Management",
    "Oil & Energy",
    "Online Publishing",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging & Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary",
    "Printing",
    "Professional Training",
    "Program Development",
    "Public Policy",
    "Public Relations",
    "Public Safety",
    "Publishing",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate",
    "Recreational",
    "Facilities & Services",
    "Religious Institutions",
    "Renewables & Environment",
    "Research",
    "Restaurants",
    "Retail",
    "Security & Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Staffing & Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Translation & Localization",
    "Transportation/Trucking/Railroad",
    "Utilities",
    "Venture Capital",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine & Spirits",
    "Wireless",
    "Writing & Editing"
]


const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
    assessments: state.assessment.ownershipData,
    accessCode: state.takingAssessment.accessCode,
});

const mapDispatchToProps = dispatch => ({
    updateAssessor: (data) => dispatch(updateAssessor(data)),
    updateTakingAssessment: (bool) => dispatch(updateTakingAssessment(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationForm)